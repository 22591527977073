import React, { useState } from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby"
import Slider from "react-slick";
import readMoreIcon from "../../images/circle-right-regular.svg"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./../Home/ServicesSection.css";

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    scrollable: true,
    slidesToShow: 1,
    slidesToScroll: 1
};


function ServicesSection(props) {
    const data = props.data;
    const services = props.services;
    return (
        <div
            className="container-cs px-4 md:px-0 py-16 md:py-32 m-auto"
        >

            <div className="w-full px-4 pb-0 md:pb-8 block text-center" >
                <div className='ss-subtitle py-2' style={{ float: "inherit" }}>
                    <span>{data.subtitle}</span>
                </div>
                <div className='pb-2'>
                    <h2 className='w-full text-center' >
                        {data.title}
                    </h2>
                    <div className='spacer-yellow m-auto'><div><div style={{ height: "3px" }}></div></div></div>
                </div>

            </div>
            <div className='flex flex-wrap'>
                {services && services.map((service, i) => (
                    <div key={`servise-slider-${i}`} className={`md:w-1/3 px-4 w-full md:pb-16 pb-4 pt-8 md:pt-0`}>
                        <a href={service.node.uri} className='service-wrapper '>
                            <div className='w-full flex flex-wrap md:flex-nowrap justify-center'>
                                <div className='service-icon-float' style={{width: "90px"}}>
                                    <GatsbyImage
                                        image={service.node.serviceAcf.serviceIcon?.localFile.childImageSharp.gatsbyImageData}
                                        alt={service.node.serviceAcf.serviceIcon?.altText}
                                        className="m-auto"
                                    />
                                </div>
                                <div className='pl-0 md:pl-4'>
                                    <h3 className='text-center md:text-left ss-h3 pb-4 pt-4 md:pt-0'>{service.node.title}</h3>
                                    <div className='text-center md:text-left ss-exc' dangerouslySetInnerHTML={{ __html: service.node.excerpt }} />
                                </div>
                            </div>
                        </a>
                    </div>
                ))}
            </div>

        </div>
    );
}

export default ServicesSection;
